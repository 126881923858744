import React from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { mapStyles } from "./mapStyles";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import MapDirectionsRenderer from "./MapDirectionsRenderer";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import CancelIcon from "@mui/icons-material/Cancel";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LanguageSelect from "./LanguageSelector";
import jeziki from "./jeziki";
import znamenitosti from "./znam.json";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const center = {
  lat: 46.68558817740814,
  lng: 16.390028964188577,
};

const options = {
  styles: mapStyles,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
};

const ustvarjenePoti = [
  {
    ime: {
      hu: "Kerékpárral Kapornakon és Hodoson",
      si: "S kolesom po Krplivniku in Hodošu",
      de: "Mit dem Fahrrad rund um Krplivnik und Hodoš",
      en: "Bike ride from Krplivnik to Hodoš",
    },
    pot: [
      { ime: "Stražnistolp v Krplivniku", lokacija: "hodos", id: 2 },
      { ime: "Etnografska hiša", lokacija: "hodos", id: 1 },
      { ime: "Evangeličanska cerkev", lokacija: "hodos", id: 5 },
      { ime: "Stražni stolp", lokacija: "hodos", id: 3 },
      { ime: "Hodoško jezero", lokacija: "hodos", id: 4 },
    ],
  },
  {
    ime: {
      hu: "Gyalogtúra Domonkosfán",
      si: "Pohod po Domanjševcih",
      de: "Wanderung durch Domanjševci",
      en: "Hike in Domanjševci",
    },
    pot: [
      { ime: "Čahukov mlin", lokacija: "salovci", id: 3 },
      { ime: "Cerkev sv. Martina", lokacija: "salovci", id: 1 },
      { ime: "Evangeličanska cerkev", lokacija: "salovci", id: 5 },
      { ime: "Žlebičev mlin", lokacija: "salovci", id: 2 },
      { ime: "Domanjševska domačija", lokacija: "salovci", id: 4 },
      { ime: "Zvonik v Domanjševcih", lokacija: "salovci", id: 6 },
    ],
  },
  {
    ime: {
      hu: "Szakrális emlékművek nyomában",
      si: "Po sledeh sakralnih spomenikov ",
      de: "Auf den Spuren der Sakraler Monumente",
      en: "In the footsteps of sacral monuments",
    },
    pot: [
      { ime: "Reformatska kapelica", lokacija: "moravske", id: 12 },
      { ime: "Rimokatoliška kapela sv. Jožefa", lokacija: "moravske", id: 10 },
      { ime: "Evangeličanska kapelica", lokacija: "moravske", id: 11 },
      { ime: "Lesen vaški zvonik", lokacija: "moravske", id: 6 },
      { ime: "Neoromanska kapela", lokacija: "moravske", id: 1 },
      { ime: "Reformatorska cerkev", lokacija: "moravske", id: 5 },
    ],
  },
  {
    ime: {
      hu: "A természet ölelésében",
      si: "V objemu narave",
      de: "In der Umarmung der Natur",
      en: "In the embrace of nature",
    },
    pot: [
      { ime: "Bukovniško jezero", lokacija: "dobrovnik", id: 1 },
      { ime: "Vidov izvir", lokacija: "dobrovnik", id: 3 },
      { ime: "Kapelica sv. Vida", lokacija: "dobrovnik", id: 2 },
      { ime: "Energijske točke", lokacija: "dobrovnik", id: 4 },
      { ime: "Hiša Györgya Dobronokija", lokacija: "dobrovnik", id: 6 },
      { ime: "Hiša rokodelstev", lokacija: "dobrovnik", id: 7 },
      { ime: "Park prijateljstva Hetés", lokacija: "dobrovnik", id: 8 },
    ],
  },
  {
    ime: {
      hu: "Gazdag történelmű városközpont",
      si: "Osrčje mesta z bogato zgodovino",
      de: "Das Herz der Stadt mit einer reichen Geschichte",
      en: "The heart of the city with a rich history",
    },
    pot: [
      { ime: "Evangeličanska cerkev", lokacija: "lendava", id: 23 },
      { ime: "Poslopje današnje knjižnice v Lendavi", lokacija: "lendava", id: 33 },
      { ime: "Gledališka in koncertna dvorana Lendava", lokacija: "lendava", id: 24 },
      { ime: "Sinagoga", lokacija: "lendava", id: 25 },
      { ime: "Muzej meščanstva, tiskarstva in dežnikarstva", lokacija: "lendava", id: 27 },
      { ime: "Lendavski grad", lokacija: "lendava", id: 21 },
      { ime: "Zgradba nekdanje meščanske šole", lokacija: "lendava", id: 32 },
      { ime: "Župnijska cerkev sv. Katarine Aleksandrijske", lokacija: "lendava", id: 29 },
    ],
  },
];

function App() {
  const [openInfo, setOpenInfo] = React.useState("");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [language, setLanguage] = React.useState("hu");
  const [izbranaPot, setIzbranaPot] = React.useState(null);
  const [mobileVisible, setMobileVisible] = React.useState(true);
  const [computerVisible, setComputerVisible] = React.useState(true);
  const [allMarkers, setAllMarkers] = React.useState([]);
  const [pot, setPot] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [ustvarjanjePoti, setUstvarjanjePoti] = React.useState(false);
  const [mojaPot, setMojaPot] = React.useState([]);
  const [izrisanaMojaPot, setIzrisanaMojaPot] = React.useState(false);
  React.useEffect(() => {
    let seznamVseh = [];
    Object.keys(znamenitosti).map((imeLokacije) => {
      znamenitosti[imeLokacije].map((znamenitost) => {
        seznamVseh.push(znamenitost);
      });
    });
    setAllMarkers(seznamVseh);
  }, []);
  const handleClickAddToMojaPot = (idTAG) => {
    let zn = allMarkers.find((marker) => marker.idTAG === idTAG);
    setMojaPot((oldArray) => [...oldArray, zn]);
  };
  const izbrisiIzMojePoti = (index) => {
    let newArray = mojaPot;
    newArray.splice(index, 1);
    setMojaPot([...newArray]);
  };
  const premakniNizje = (index) => {
    let newArray = mojaPot;
    let lowerItem = newArray[index];
    let upperItem = newArray[index + 1];
    newArray[index] = upperItem;
    newArray[index + 1] = lowerItem;
    setMojaPot([...newArray]);
  };
  const premakniVisje = (index) => {
    let newArray = mojaPot;
    let lowerItem = newArray[index];
    let upperItem = newArray[index - 1];
    newArray[index] = upperItem;
    newArray[index - 1] = lowerItem;
    setMojaPot([...newArray]);
  };
  const handleClick = (potIndex) => {
    if (potIndex === izbranaPot) {
      setIzbranaPot(null);
      setShow(false);
      setPot([]);
    } else {
      let poti = [];
      ustvarjenePoti[potIndex].pot.map((item) => {
        let zn = znamenitosti[item.lokacija].find((izVsehZnamenitosti) => izVsehZnamenitosti.id === item.id);
        poti.push({ latitude: zn.coordinates.lat, longitude: zn.coordinates.lng });
      });

      setPot(poti);
      setMobileVisible(false);
      setIzbranaPot(potIndex);
      setShow(true);
    }
  };
  const handleClickComputer = (potIndex) => {
    if (potIndex === izbranaPot) {
      setIzbranaPot(null);
      setShow(false);
      setPot([]);
    } else {
      let poti = [];
      ustvarjenePoti[potIndex].pot.map((item) => {
        let zn = znamenitosti[item.lokacija].find((izVsehZnamenitosti) => izVsehZnamenitosti.id === item.id);
        poti.push({ latitude: zn.coordinates.lat, longitude: zn.coordinates.lng });
      });

      setPot(poti);
      setComputerVisible(false);
      setIzbranaPot(potIndex);
      setShow(true);
    }
  };
  const izrisiMojoPot = () => {
    let poti = [];
    mojaPot.map((item) => {
      poti.push({ latitude: item.coordinates.lat, longitude: item.coordinates.lng });
    });
    setOpenInfo("");
    setPot(poti);
    setShow(true);
    setIzrisanaMojaPot(true);
  };
  const odstraniMojoPot = () => {
    setShow(false);
    setPot([]);
    setIzrisanaMojaPot(false);
  };
  const handleBackIzMojePoti = () => {
    setUstvarjanjePoti(false);
    setShow(false);
    setPot([]);
    setIzrisanaMojaPot(false);
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  let languageSELECTOR = language.toUpperCase();
  let nameSelector = "name" + languageSELECTOR;
  let opisSelector = "opis" + languageSELECTOR;
  console.log(pot);
  return (
    <div className="App">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={11} options={options}>
        {allMarkers
          ? [...allMarkers].map((currentMarker) => (
              <Marker key={currentMarker.idTAG} position={currentMarker.coordinates} onClick={() => setOpenInfo(currentMarker.idTAG)}>
                <g fill="none" stroke="#FF5533" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(-12, -24)">
                  <circle cx="12" cy="10" r="3" />
                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>
                <text textAnchor="middle" y={currentMarker.markerOffset} style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}>
                  {currentMarker[nameSelector]}
                </text>
                {openInfo === currentMarker.idTAG ? (
                  <InfoWindow onCloseClick={() => setOpenInfo("")}>
                    <div>
                      <h1>{currentMarker[nameSelector]}</h1>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={currentMarker.img} alt={currentMarker.img} width="50%"></img>
                      </div>
                      <p style={{ fontWeight: "400", fontSize: "20px" }}>{currentMarker[opisSelector]}</p>
                      <Button onClick={() => handleClickAddToMojaPot(currentMarker.idTAG)}>{jeziki[language].dodaj}</Button>
                    </div>
                  </InfoWindow>
                ) : (
                  <div></div>
                )}
              </Marker>
            ))
          : ""}

        {show && <MapDirectionsRenderer places={pot} travelMode={"WALKING"} />}
      </GoogleMap>
      {ustvarjanjePoti ? (
        <Container sx={{ display: { xs: "block", md: "block" } }}>
          <div
            style={{
              width: "30vw",
              minWidth: "350px",
              /* height: "80vh", */ backgroundColor: "#dddddd",
              position: "fixed",
              top: "20px",
              left: "5px",
            }}
          >
            <LanguageSelect language={language} setLanguage={setLanguage} />
            <List
              sx={{ width: "100%", bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {jeziki[language].ustvarjenePoti}
                </ListSubheader>
              }
            >
              <Button onClick={() => handleBackIzMojePoti()}>{jeziki[language].textNazaj}</Button>
              {mojaPot.map((mojaPotItem, mojaPotIndex) => {
                return (
                  <ListItemButton key={mojaPotItem.ime + mojaPotIndex} sx={{ pl: 4 }} style={{ width: "100%" /* backgroundColor: color */ }}>
                    <ListItemIcon onClick={() => setOpenInfo(mojaPotItem.idTAG)}>
                      <RemoveRedEyeIcon />
                    </ListItemIcon>
                    <ListItemText primary={mojaPotItem[nameSelector]} />
                    {izrisanaMojaPot ? (
                      ""
                    ) : mojaPotIndex === 0 ? (
                      <ListItemIcon></ListItemIcon>
                    ) : (
                      <ListItemIcon onClick={() => premakniVisje(mojaPotIndex)}>
                        <ArrowUpwardIcon />
                      </ListItemIcon>
                    )}
                    {izrisanaMojaPot ? (
                      ""
                    ) : mojaPotIndex === mojaPot.length - 1 ? (
                      <ListItemIcon></ListItemIcon>
                    ) : (
                      <ListItemIcon onClick={() => premakniNizje(mojaPotIndex)}>
                        <ArrowDownwardIcon />
                      </ListItemIcon>
                    )}
                    {izrisanaMojaPot ? (
                      ""
                    ) : (
                      <ListItemIcon onClick={() => izbrisiIzMojePoti(mojaPotIndex)}>
                        <CancelIcon />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                );
              })}
            </List>
            {izrisanaMojaPot ? (
              <Button onClick={odstraniMojoPot}>{jeziki[language].skrij}</Button>
            ) : (
              <Button onClick={izrisiMojoPot}>{jeziki[language].izrisi}</Button>
            )}
          </div>
        </Container>
      ) : !openInfo ? (
        <>
          <Container sx={{ display: { xs: "none", md: "block" } }} style={{ position: "fixed", top: "20px", left: "5px", paddingLeft: "0px" }}>
            <Button
              variant="contained"
              startIcon={computerVisible ? <MenuOpenIcon /> : <MenuIcon />}
              onClick={() => setComputerVisible(!computerVisible)}
            >
              {computerVisible ? jeziki[language].zapriPoti : jeziki[language].odpriPoti}
            </Button>
          </Container>
          <Container sx={{ display: { xs: "none", md: computerVisible ? "block" : "none" } }}>
            <div
              style={{
                width: "30vw",
                minWidth: "350px",
                /* height: "80vh", */ backgroundColor: "#dddddd",
                position: "fixed",
                top: "55px",
                left: "5px",
              }}
            >
              <LanguageSelect language={language} setLanguage={setLanguage} />
              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {jeziki[language].ustvarjenePoti}
                  </ListSubheader>
                }
              >
                {ustvarjenePoti.map((ustvarjenaPotPodatki, ustvarjenaPotIndex) => {
                  /* let color = ustvarjenaPotIndex === izbranaPot ? "#DDDDDD" : "#FFFFFF"; */
                  if (izbranaPot === ustvarjenaPotIndex) {
                    return (
                      <>
                        <ListItemButton
                          key={ustvarjenaPotPodatki.ime[language] + ustvarjenaPotIndex}
                          onClick={() => handleClick(ustvarjenaPotIndex)}
                          style={{ width: "100%" /* backgroundColor: color  */ }}
                        >
                          <ListItemIcon>
                            <DirectionsWalkIcon />
                          </ListItemIcon>
                          <ListItemText primary={ustvarjenaPotPodatki.ime[language]} />
                          {izbranaPot === ustvarjenaPotIndex ? <CancelIcon /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={izbranaPot === ustvarjenaPotIndex} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {ustvarjenaPotPodatki.pot.map((lokacijaPodatki, lokacijaIndex) => {
                              return (
                                <ListItemButton
                                  key={lokacijaPodatki.ime + lokacijaIndex}
                                  sx={{ pl: 4 }}
                                  style={{ width: "100%" /* backgroundColor: color */ }}
                                >
                                  <ListItemIcon>
                                    <RemoveRedEyeIcon
                                      onClick={() => setOpenInfo(znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1].idTAG)}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1][nameSelector]} />
                                </ListItemButton>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    );
                  } else if (izbranaPot != null) {
                  } else {
                    return (
                      <>
                        <ListItemButton onClick={() => handleClick(ustvarjenaPotIndex)} style={{ width: "100%" /* backgroundColor: color */ }}>
                          <ListItemIcon>
                            <DirectionsWalkIcon />
                          </ListItemIcon>
                          <ListItemText primary={ustvarjenaPotPodatki.ime[language]} />
                          {izbranaPot === ustvarjenaPotIndex ? <CancelIcon /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={izbranaPot === ustvarjenaPotIndex} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {ustvarjenaPotPodatki.pot.map((lokacijaPodatki, lokacijaIndex) => {
                              return (
                                <ListItemButton
                                  key={lokacijaPodatki.ime + lokacijaIndex}
                                  sx={{ pl: 4 }}
                                  style={{ width: "100%" /* backgroundColor: color */ }}
                                >
                                  <ListItemIcon onClick={() => setOpenInfo(znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1].idTAG)}>
                                    <RemoveRedEyeIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1][nameSelector]} />
                                </ListItemButton>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    );
                  }
                })}
              </List>
              {izbranaPot != null ? "" : <Button onClick={() => setUstvarjanjePoti(true)}>{jeziki[language].ustvari}</Button>}
            </div>
          </Container>
          <Container sx={{ display: { xs: "block", md: "none" } }} style={{ position: "fixed", top: "20px", left: "5px", paddingLeft: "0px" }}>
            <Button variant="contained" startIcon={mobileVisible ? <MenuOpenIcon /> : <MenuIcon />} onClick={() => setMobileVisible(!mobileVisible)}>
              {mobileVisible ? jeziki[language].zapriPoti : jeziki[language].odpriPoti}
            </Button>
          </Container>
          <Container sx={{ display: { xs: mobileVisible ? "block" : "none", md: "none" } }}>
            <div
              style={{
                width: "80vw",
                minWidth: "350px",
                /* height: "80vh", */ backgroundColor: "#dddddd",
                position: "fixed",
                top: "55px",
                left: "5px",
              }}
            >
              <LanguageSelect language={language} setLanguage={setLanguage} />
              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {jeziki[language].ustvarjenePoti}
                  </ListSubheader>
                }
              >
                {ustvarjenePoti.map((ustvarjenaPotPodatki, ustvarjenaPotIndex) => {
                  /* let color = ustvarjenaPotIndex === izbranaPot ? "#DDDDDD" : "#FFFFFF"; */
                  if (izbranaPot === ustvarjenaPotIndex) {
                    return (
                      <>
                        <ListItemButton
                          key={ustvarjenaPotPodatki.ime[language] + ustvarjenaPotIndex}
                          onClick={() => handleClick(ustvarjenaPotIndex)}
                          style={{ width: "100%" /* backgroundColor: color  */ }}
                        >
                          <ListItemIcon>
                            <DirectionsWalkIcon />
                          </ListItemIcon>
                          <ListItemText primary={ustvarjenaPotPodatki.ime[language]} />
                          {izbranaPot === ustvarjenaPotIndex ? <CancelIcon /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={izbranaPot === ustvarjenaPotIndex} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {ustvarjenaPotPodatki.pot.map((lokacijaPodatki, lokacijaIndex) => {
                              return (
                                <ListItemButton
                                  key={lokacijaPodatki.ime + lokacijaIndex}
                                  sx={{ pl: 4 }}
                                  style={{ width: "100%" /* backgroundColor: color */ }}
                                >
                                  <ListItemIcon onClick={() => setOpenInfo(znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1].idTAG)}>
                                    <RemoveRedEyeIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1][nameSelector]} />
                                </ListItemButton>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    );
                  } else if (izbranaPot != null) {
                  } else {
                    return (
                      <>
                        <ListItemButton onClick={() => handleClick(ustvarjenaPotIndex)} style={{ width: "100%" /* backgroundColor: color */ }}>
                          <ListItemIcon>
                            <DirectionsWalkIcon />
                          </ListItemIcon>
                          <ListItemText primary={ustvarjenaPotPodatki.ime[language]} />
                          {izbranaPot === ustvarjenaPotIndex ? <CancelIcon /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={izbranaPot === ustvarjenaPotIndex} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {ustvarjenaPotPodatki.pot.map((lokacijaPodatki, lokacijaIndex) => {
                              return (
                                <ListItemButton
                                  key={lokacijaPodatki.ime + lokacijaIndex}
                                  sx={{ pl: 4 }}
                                  style={{ width: "100%" /* backgroundColor: color */ }}
                                >
                                  <ListItemIcon onClick={() => setOpenInfo(znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1].idTAG)}>
                                    <RemoveRedEyeIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={znamenitosti[lokacijaPodatki.lokacija][lokacijaPodatki.id - 1][nameSelector]} />
                                </ListItemButton>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    );
                  }
                })}
              </List>
              {izbranaPot != null ? "" : <Button onClick={() => setUstvarjanjePoti(true)}>{jeziki[language].ustvari}</Button>}
            </div>
          </Container>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
