// eslint-disable-next-line import/no-anonymous-default-export
export default {
  si: {
    ime: "Slovenščina",
    navodila: "navodila",
    jezik: "Izbira jezika",
    ustvarjenePoti: "Ustvarjene poti",
    odpriPoti: "Pokaži poti",
    zapriPoti: "Skrij poti",
    textNazaj: "Nazaj",
    dodaj: "Dodaj v mojo pot",
    ustvari: "Ustvari svojo pot",
    izrisi: "Pokaži mojo pot",
    skrij: "Skrij mojo pot",
  },
  hu: {
    ime: "Magyar",
    navodila: "navodila",
    jezik: "Nyelvválasztás",
    ustvarjenePoti: "Útitervek",
    odpriPoti: "Útitervek megjelenítése",
    zapriPoti: "Útitervek elrejtése",
    textNazaj: "Vissza",
    dodaj: "Hozzáadás az útvonalamhoz",
    ustvari: "Hozza létre az útvonalat",
    izrisi: "Mutasd az útvonalamat",
    skrij: "Rejtsd el az útvonalat",
  },
  en: {
    ime: "English",
    navodila: "navodila",
    jezik: "Language select",
    ustvarjenePoti: "Created routes",
    odpriPoti: "Show routes",
    zapriPoti: "Hide routes",
    textNazaj: "Back",
    dodaj: "Add to my route",
    ustvari: "Create your route",
    izrisi: "Show my route",
    skrij: "Hide my route",
  },
  de: {
    ime: "Deutsch",
    navodila: "navodila",
    jezik: "Sprachauswahl",
    ustvarjenePoti: "Reiserouten",
    odpriPoti: "Routen anzeigen",
    zapriPoti: "Routen ausblenden",
    textNazaj: "Zuruck",
    dodaj: "Zu meiner Route hinzufügen",
    ustvari: "Erstelle deine Route",
    izrisi: "Meine Route anzeigen",
    skrij: "Meine Route ausblenden",
  },
};
