import React, { useState } from "react";
/* import { AuthContext } from "../auth"; */
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import ListSubheader from "@material-ui/core/ListSubheader";
import jeziki from "./jeziki";
/* import "flag-icon-css/css/flag-icon.min.css"; */
import Avatar from "@mui/material/Avatar";
import iconSI from "./Slike/si.png";
import iconHU from "./Slike/hu.png";
import iconEN from "./Slike/us.png";
import iconDE from "./Slike/de.png";

const languageMap = {
  si: { short: "si", label: "Slovenščina", dir: "ltr", active: false },
  hu: { short: "hu", label: "Magyar", dir: "ltr", active: true },
  en: { short: "en", label: "English", dir: "ltr", active: false },
  de: { short: "de", label: "Deutsch", dir: "ltr", active: false },
};

const LanguageSelect = (props) => {
  const selected = localStorage.getItem("i18nextLng") || "en";
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const { language, setLanguage } = props;
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);
  /* const authContext = React.useContext(AuthContext) */ const handleChange = (panel) => {
    setLanguage(panel);
    setMenuAnchor(null);
  };
  let activeIcon;
  if (language === "si") activeIcon = iconSI;
  if (language === "hu") activeIcon = iconHU;
  if (language === "en") activeIcon = iconEN;
  if (language === "de") activeIcon = iconDE;
  return (
    <div className="d-flex justify-content-end align-items-center language-select-root" style={{ backgroundColor: "#FFFFFF", width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Box sx={{ display: "flex", p: 1, bgcolor: "background.paper" }}>
          <Box sx={{ p: 1, flexGrow: 1 }}>
            <Typography variant="h5">{jeziki[language].jezik}</Typography>
          </Box>
          <Box sx={{ p: 1 }}>
            <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
              <Avatar alt="hu" src={activeIcon} style={{ marginRight: "5px" }} sx={{ width: 24, height: 24 }} />
              {jeziki[language].ime}
              <ArrowDropDown fontSize="small" />
            </Button>
          </Box>
        </Box>
        {/* </div> */}
        {/* <div style={{ width: "49%" }}>Izbira jezika</div>
      <div style={{ width: "49%" }}> */}
      </div>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List>
            <ListItem button key={"si"} onClick={() => handleChange("si")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="si" src={iconSI} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"Slovenščina"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem button key={"hu"} onClick={() => handleChange("hu")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="hu" src={iconHU} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"Magyar"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem button key={"en"} onClick={() => handleChange("en")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="en" src={iconEN} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"English"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem button key={"de"} onClick={() => handleChange("de")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="de" src={iconDE} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"Deutsch"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
